import css from '@styled-system/css';
import CaseStudyListingBlock from 'blocks/CaseStudyListingBlock';
import Footer from 'components/Footer';
import {graphql} from 'gatsby';
import {HelmetDatoCms} from 'gatsby-source-datocms';
import {withGlobals} from 'globals';
import React, {useEffect} from 'react';
import {useSpring} from 'react-spring';
import {withTheme} from 'styled-components';
import {Text} from 'system';
import {Column, Container, Row, Section} from 'system/layout';
import theme from 'theme';

function WorkPage({theme, footerData, setLogoColor, data, setPageReady}) {
	useEffect(() => {
		setLogoColor(theme.colors.blue);
		setPageReady(true);
	}, [theme.colors.blue, setLogoColor, setPageReady]);

	const {workHeading} = data.page;
	const headingSpring = useSpring({
		from: {opacity: 0, transform: `translate3d(0, 10px, 0)`},
		opacity: 1,
		transform: `translate3d(0, 0, 0)`,
	});
	return (
		<>
			<HelmetDatoCms seo={data.page.seoMetaTags}/>
			<Section>
				<Container>
					<Row top={[100, 100, 115, 130]} zIndex={1} style={headingSpring}>
						<Column>
							<Text
								as={`h1`}
								color={theme.colors.blue}
								fontSize={[30, 36, 42, 54, 68]}
								lineHeight={1}
								fontWeight={`bold`}
								fontFamily={theme.fonts.headings}
								width={[3 / 5, 1 / 2, 1]}
								dangerouslySetInnerHTML={{
									__html: workHeading.replace(/<p[^>]*>/g, ''),
								}}
								css={`
                                  & strong {
                                    ${css({
                                      // display: [`block`, `block`, `inline`],
                                      '-webkit-text-stroke-width': [`1.5px`, `1.5px`, `2px`],
                                    })}
                                    color: white;
                                    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
                                    -webkit-text-stroke-color: ${theme.colors.blue};
                                  }
								`}
							/>
						</Column>
					</Row>
				</Container>
			</Section>
			<CaseStudyListingBlock
				cases={data.page.caseStudies.filter(c => !c.hide)}
				pt={[125, 125, 125, 150, 200]}
			/>
			<Footer data={footerData}/>
		</>
	);
}

WorkPage.bgColor = theme.colors.grey;

export default withGlobals(withTheme(WorkPage));

export const query = graphql`
  query($locale: String!) {
    page: datoCmsWorkPage(locale: { eq: $locale }) {
      id
      originalId
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      workHeading
      caseStudies {
        id
        hide
        listingTitle
        client {
          name
          logo {
            url
            alt
            width
            height
            fluid(
              maxWidth: 200
              imgixParams: { auto: "format,compress", q: 100 }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
        listingImage {
          url
          alt
          width
          height
          fluid(
            maxWidth: 760
            imgixParams: { auto: "format,compress", q: 90 }
          ) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }
  }
`;
